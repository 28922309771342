import { StoreModel } from "./utils"
import {CommerceAPI} from 'commerce-api'
import { Status, StatusMap } from "../types/commerce"
import { RootStore } from "./RootStore"
import { 
  makeObservable,
  observable,
  action,
  flow, 
} from "mobx"
import { formatSelfServeRefundFormData } from "../utils/self-serve-utils"
import { RefundFormData } from "../types/self-serve"
import { getAppOrigin } from "@salesforce/pwa-kit-react-sdk/utils/url"


export class RefundStore implements StoreModel {
  api: CommerceAPI
  status: Status
  rootStore: RootStore
  refundReasonCodes: string[]
  refundFormData: RefundFormData

  constructor(rootStore: RootStore, initialState = {status: StatusMap.IDLE}) {
    this.refundFormData = {}
    this.refundReasonCodes = []
    this.status = initialState?.status

    makeObservable(this, {
      exceededNumberOfDaysAfterDeliveryForRefund: action.bound,
      getRefundReasonCodes: flow.bound,
      isOrderAvailableForSelfServeRefund: action.bound,
      metMinimumNumberOfHoursAfterDeliverySlotForRefund: action.bound,
      refundFormData: observable,
      refundReasonCodes: observable,
      saveRefundForm: action.bound,
      status: observable,
    })

    this.api = rootStore.api
    this.rootStore = rootStore
  }

  get asJson() {
    return {
      status: this.status,
      refundReasonCodes: this.refundReasonCodes
    }
  }

  get isSelfServeRefundEnabled() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeEnabled as boolean
  }

  get maxDaysAfterDeliveryForRefund() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeNumberOfDaysAfterDelivery as number
  }

  get minHoursAfterOrderPlacedforRefund() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeNumberOfHoursAfterDeliverySlot as number
  }

  get minMinutesAfterLastRefundRequest() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeNumberOfMinutesAfterLastRequest as number
  }

  // Check lastRefundRequestTime on order

  // @todo: call this function when logic is implemented in BE
  enoughTimeSinceLastRefundRequest(lastRefundRequestTime: string) {
    const minMinsBetweenRefundRequests = this.minMinutesAfterLastRefundRequest
    if (!minMinsBetweenRefundRequests) return false

    const minMsBetweenRefundRequests = minMinsBetweenRefundRequests * 60000
    const currentTime = new Date().getTime()
    const lastRefundRequest = new Date(lastRefundRequestTime).getTime()
    const timeSinceLastRefundRequest = currentTime - lastRefundRequest

    return timeSinceLastRefundRequest >= minMsBetweenRefundRequests
  }

  // Compare custom pref for days between order delivery date and refund request
  exceededNumberOfDaysAfterDeliveryForRefund(numberOfDays: number) {
    const maxDaysCustomPref = this.maxDaysAfterDeliveryForRefund
    if (!maxDaysCustomPref) return false
    return numberOfDays > maxDaysCustomPref
  }

  // Fetch RefundReasonCode Custom Object from SFCC
  *getRefundReasonCodes() {
    try {
      if (this.refundReasonCodes.length === 0) {
        const res: Response = yield fetch(
          `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/SelfServeRefunds-GetReasonCodes`
        )
        const json: {reasonCodes?: string[] } = yield res.json()
        if (json?.reasonCodes && Array.isArray(json?.reasonCodes)) {
          this.refundReasonCodes = json.reasonCodes
        }
      }
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  // Self Serve Refunds
  isOrderAvailableForSelfServeRefund(daysSinceDelivery: number, hoursSinceEndOfDelivery: number) { 
    return (
      this.isSelfServeRefundEnabled &&
      daysSinceDelivery >= 0 &&
      !this.exceededNumberOfDaysAfterDeliveryForRefund(daysSinceDelivery) &&
      this.metMinimumNumberOfHoursAfterDeliverySlotForRefund(hoursSinceEndOfDelivery)
    )
  }

  // Compare custom pref for hours between order delivery slot ended/delivered and refund request
  metMinimumNumberOfHoursAfterDeliverySlotForRefund(numberOfHours: number) {
    const minHoursCustomPref = this.minHoursAfterOrderPlacedforRefund
    if (!minHoursCustomPref) return false
    return numberOfHours >= minHoursCustomPref
  }

  // Save the form data to the store
  saveRefundForm(data: RefundFormData) {
    const formattedData: RefundFormData = formatSelfServeRefundFormData(data)
    // Save the formatted data to the store
    this.refundFormData = formattedData
    // For testing, log the data to the console
    console.log('Refund Form Data:', this.refundFormData)
  }

}
