import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useGlobalStore} from '../../store/hooks/useStore'
import {Container, Stack} from '../../vanilla'
import {HitItem} from '../algolia/instantsearch/hits'
import {Carousel} from '../carousel'
import * as styles from '../recommendations/styles.css'
import {Hit} from '../../store/ProductStore'

interface ProductCategoryCarouselProps {
  title?: string
  categoryId?: string
}

const ProductCategoryCarouselContainer = ({
  title,
  categoryId
}: ProductCategoryCarouselProps) => {
  const {
    searchClient,
    algoliaIndexBase,
    selectedAvailabilityDayOffset,
    selectedStoreId,
    selectedPriceDayOffset,
    selectedRegion,
  } = useGlobalStore()
  const algoliaIndex = `${algoliaIndexBase}__products__default`

  //Facet filters to match what we render on PLP
  const categoryFilter = `categoryPageId:${categoryId}`
  const availabilityFilter = `matrixAvailability.${selectedAvailabilityDayOffset}:-${selectedStoreId}`
  const inStockFilter = `in_stock:true`
  const atbAllowedFilter = `isAddToCartDisabled:false`
  const priceFilter = `matrixPrices.${selectedPriceDayOffset}.${selectedRegion}>0`

  const [hits, setHits] = useState<Array<Hit>>([])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const index = searchClient.initIndex(algoliaIndex)
        const results = await index.search('', {
          facetFilters: [
            categoryFilter,
            availabilityFilter,
            inStockFilter,
            atbAllowedFilter,
            priceFilter,
          ],
          hitsPerPage: 10,
        })
        setHits(results.hits as Array<Hit>)
      } catch (error) {
        console.error('Error fetching products from Algolia for product category carousel:', error)
      }
    }

    fetchProducts()
  }, [])

  return (
    <Container paddingX={['12px', '32px']} position="relative">
      <Stack gap={['20px', '28px']} position="relative">
        {title && (
          <h2
            style={{
              width: '100%',
              textAlign: 'center',
              borderBottom: '1px solid #d8d8d8',
              lineHeight: '0px',
              margin: '20px 0px',
            }}
          >
            <span className={styles.recommenderTitle}>{title}</span>
          </h2>
        )}
        <Carousel
          loop={false}
          className="recommendations-carousel"
          itemClassName={styles.recommenderItemSpacing}
          controlsPosition="bottom"
          controlConfig={{
            activeDotColor: 'gray800',
            showArrows: { mobile: false, desktop: true, tablet: true },
            arrowsColor: 'gray800',
          }}
          customResponsiveConfig={{
            desktop: {
              breakpoint: { min: 1024, max: 99999 },
              items: 5,
              slidesToSlide: 5,
            },
            tablet: {
              breakpoint: { max: 1024, min: 780 },
              items: 4,
              slidesToSlide: 4,
            },
            mobile: {
              breakpoint: { max: 780, min: 480 },
              items: 2,
              slidesToSlide: 2,
            },
            'mobile-xs': {
              breakpoint: { max: 480, min: 0 },
              items: 1,
              slidesToSlide: 1,
              partialVisibilityGutter: 50,
            },
          }}
        >
          {hits?.map((hit) => {
            return (
              hit && (
                <HitItem
                  hit={hit as Hit}
                  isPLPTile={true}
                  key={hit.id}
                  className={styles.recommenderHit}
                />
              )
            )
          })}
        </Carousel>
      </Stack>
    </Container>
  )
}

export const ProductCategoryCarousel = observer(ProductCategoryCarouselContainer)
