import "app/components/header/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/header/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81WXY/qIBB991eQ3Bf7gKlddd36cv/JhgJaIkIDdKt743+/FNQLtX40u5vcpyb0zMyZmTMDk/fpNuNbkaV5KT+oAn9GAGDJpcrBB1JjCN+ny+Z1UTQZSlb2n6F7AwnFUiHDpMhBLQhVnAm6Gh1Hk7O7aS6kGedrprSBuGScJM51IZWFQ07X9tiHMQoJXSFFhYlcZM7A/WU+FOIcZGm60y2RT8hs5H0OppHVi8+gVrr1XUkmDFUtvkB4u1HS0oX9+c2SyNGs31GICEo21PvcWa2lMLChbFOarsGLig0Wt/LqD1d0rL+3u6/OEWG64uiQgzWn+9a6/ULCFMXeWMmmPUacbQRkhu50DjA9E68QIUxsnBa6dDZJiDCy6gKKCFBIY+SuDxOSHtKuLLZdfqn6yyDyvZab5CmhnriVFLWjhEuKt7I2sKhtEYQPldveoIJTcqfj6Y1ov+YYz4q3KIM35wdVFUV2HjHNgZCtJgCoteWgKbc9/3fYlLbb0A61RzYKVat7s9xTWFcnzT7pVZVYsnpQx8ohWtXCsh9x8Ln7ZaQQYbXuYmbb5HaD9XOdSoMwV/7LkEO//TyEeJH71XmmnFb7AGHn5N5vP2juLGztw9VwSyg9NYn89mnwIi4ApJUHM4fuAkffIrXLbmLC6eC8ooZI8LRdLMFTHf8XTV63IrpD722lr6oxbNTQ6++B+0XXfaSfi1rSyWLeew0PuFtN0nkzDLb9vaOEIaCxolQAJAgY75iADSOmbAWTzaq9f/EECbknEUfxi6g736qN2p3gaMRvIM6XofK0u5bx8fEBs8u/4kdJTm+w7BXnU8vyODr+BQLmwC7UCgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var checkoutButton = '_1k2lkn29';
export var linkUtilityMenu = '_1k2lkn20';
export var megaMenuInternalItem = '_1k2lkn24';
export var megaMenuInternalItemActive = '_1k2lkn23';
export var megaMenuInternalItemText = '_1k2lkn25';
export var megaMenuListItem = '_1k2lkn21';
export var megaMenuSection = '_1k2lkn22';
export var mobileNavigationMenuBackLink = '_1k2lkn27';
export var mobileNavigationMenuListItem = '_1k2lkn28';
export var mobileNavigationMenuListItemRoot = '_1k2lkn26';
export var viewBasketButton = '_1k2lkn2a';
export var viewBasketContent = '_1k2lkn2b';