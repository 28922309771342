import React, {ChangeEvent, ChangeEventHandler, FocusEventHandler} from 'react'
import {Controller, Control, RegisterOptions, FieldError} from 'react-hook-form'
import {EyeIcon, ExclamationCircleIcon} from '../icons'
import {Box, Button, Input, Select, Text, Stack, Checkbox, BoxProps, TextProps} from '../../vanilla'
import {useToggle} from '../../hooks/use-toggle'
import {InputProps} from '../../vanilla/components/Input'
import {Textarea} from '../../vanilla'

// @todo: we need to revisit this typing for inputProps (it's incompatible with input and select variations)
export type FieldInputProps = Omit<
  InputProps<typeof Box<'input'>>,
  'hasError' | 'as' | 'ref' | 'onChange' | 'validatedSuccessfully' | 'onBlur'
> & {
  onChange?: ChangeEventHandler<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  showAsterisk?: boolean
}

export type FieldType =
  | 'text'
  | 'number'
  | 'password'
  | 'email'
  | 'phone'
  | 'tel'
  | 'select'
  | 'checkbox'
  | 'hidden'
  | 'textarea'

export interface Field {
  name: string
  label?: string
  showAsterisk?: boolean
  formLabel?: React.ReactNode
  id?: string
  type?: FieldType
  options?: Array<{label: React.ReactNode; value: string | number | string[]}>
  rules?: Pick<
    RegisterOptions,
    'required' | 'min' | 'max' | 'minLength' | 'maxLength' | 'pattern' | 'validate'
  >
  error?: FieldError
  placeholder?: string
  inputProps?:
    | FieldInputProps
    | ((state: {value: any; onChange: (value: any) => void; onBlur: () => void}) => FieldInputProps)
  inputGroupProps?: BoxProps<'div'>
  control: Control<any>
  defaultValue?: string | boolean | number
  helpText?: React.ReactNode
  children?: React.ReactNode
  errorTextProps?: TextProps<'span'>
  onSubmitValidation?: boolean
  disabled?: boolean
  hideError?: boolean
}

export const Field = ({
  name,
  label,
  showAsterisk,
  formLabel,
  type = 'text',
  options = [],
  rules = {},
  error,
  placeholder,
  inputProps,
  inputGroupProps,
  control,
  defaultValue,
  helpText,
  children,
  errorTextProps = {},
  onSubmitValidation = false,
  disabled = false,
  hideError = false
}: Field) => {
  const [hidePassword, toggleHidePassword] = useToggle(true)

  const inputType =
    type === 'password' && hidePassword ? 'password' : type === 'password' ? 'text' : type

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={(renderProps) => {
        const {
          field: {onChange, value, ref, onBlur},
          fieldState: {invalid, isTouched},
          formState: {errors, isSubmitted}
        } = renderProps

        const _inputProps =
          typeof inputProps === 'function' ? inputProps({value, onChange, onBlur}) : inputProps

        return (
          <Stack spacing="8px" width="full" role="group">
            <Box position="relative" {...inputGroupProps}>
              {['text', 'password', 'email', 'phone', 'tel', 'number'].includes(type) && (
                <Input
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  label={label}
                  showAsterisk={showAsterisk}
                  name={name}
                  type={inputType}
                  placeholder={placeholder}
                  disabled={disabled}
                  hasError={error != null || ((type === 'email' || type === 'password') && !!errors.global)}
                  // On login, only validate the fields on submit
                  validatedSuccessfully={
                    onSubmitValidation
                      ? isSubmitted && !invalid && isTouched && !errors.global
                      : !invalid && isTouched && !errors.global
                  }
                  {..._inputProps}
                />
              )}

              {type === 'hidden' && (
                <Input
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  type="hidden"
                  {..._inputProps}
                  validatedSuccessfully={!invalid && isTouched}
                />
              )}

              {type === 'password' && (
                <Box
                  position="absolute"
                  right="0px"
                  style={{top: '50%', transform: 'translateY(-50%)'}}
                >
                  <Button
                    height="auto"
                    type="button"
                    variant="unstyled"
                    size="icon"
                    aria-label="Show password"
                    onClick={(e: React.MouseEvent | React.TouchEvent) => {
                      e.preventDefault()
                      toggleHidePassword()
                    }}
                  >
                    <EyeIcon boxSize="12px" color="black" opacity={hidePassword ? '0.3' : '1'} />
                  </Button>
                </Box>
              )}

              {type === 'select' && (
                // @ts-ignore
                <Select<'select'>
                  name={name}
                  ref={ref}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChange(e.target.value)
                  }}
                  value={value}
                  label={label}
                  placeholder={placeholder}
                  hasError={error != null}
                  validatedSuccessfully={!invalid && isTouched}
                  onBlur={onBlur}
                  {..._inputProps}
                >
                  {options.map((opt) => (
                    <option key={`${opt.label}-${opt.value}`} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              )}

              {type === 'checkbox' && (
                <Box display="flex" flexDirection="row">
                  <Checkbox
                    name={name}
                    checked={value}
                    label={formLabel || label}
                    hasError={error != null ?? false}
                    {...(_inputProps || {})}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  />
                </Box>
              )}

              {type === 'textarea' && (
                // @ts-ignore
                <Textarea
                  label={label}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  value={value}
                  type="hidden"
                  onBlur={onBlur}
                  {..._inputProps}
                  placeholder={placeholder}
                  validatedSuccessfully={!invalid && isTouched}
                />
              )}

              {children}
            </Box>

            {error && type !== 'hidden' && !hideError && (
              <Text
                as="span"
                variant="text4"
                color="error"
                display="inline-block"
                marginLeft="8px"
                lineHeight="shorter"
                {...errorTextProps}
              >
                {!(error.type && error.type === "custom.noExclamation") && (
                  <ExclamationCircleIcon
                    color="error"
                    display="inline-block"
                    boxSize="12px"
                    style={{marginRight: '6.5px'}}
                  />
                )}
                {error.message}
              </Text>
            )}
            {helpText}
          </Stack>
        )
      }}
    />
  )
}
