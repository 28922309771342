import { RefundFormData, Errors } from "../types/self-serve"
import { capitalize } from "./utils"

export const formatSelfServeRefundFormData = (products: RefundFormData | undefined) => {
  if (!products) return {}

  const formattedData: RefundFormData = {}
  for (const productId in products) {
    const product = products[productId]
    if (product.reasonCode && product.reasonCode !== 'Select' && (product.quantity >= 0 || !product.quantity)) {
      formattedData[productId] = {
        reasonCode: product.reasonCode,
        quantity: product.quantity
      }
    }
  }

  // Remove objects with quantity of 0, those with quantity undefined are changed to 1
  for (const productId in formattedData) {
    if (formattedData[productId].quantity === 0) {
      delete formattedData[productId]
    }
    
    if (formattedData[productId] && !formattedData[productId].quantity) {
      formattedData[productId].quantity = 1
    }
  }
  
  return formattedData
}

export function extractErrorMessage(errors: Errors, productId: string): string | null {
  const product = errors?.[productId]
  if (product) {
    if (product.quantity?.message) return product.quantity.message
    if (product.reasonCode?.message) return product.reasonCode.message
  }
  return null
}

export const formatReasonCodes = (reasonCodes: string[]) => {
  if (!reasonCodes) return []
  
  return reasonCodes
  .slice()
  .sort((a, b) => a.localeCompare(b))
  .map((reasonCode) => `${capitalize(reasonCode)} items`)
}
