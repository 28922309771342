import React from 'react'
import LazyLoad from 'react-lazyload'
import {SlotComponent} from '../../types/ui'
import {Recommender} from '../recommendations'
import {Stack} from '../../vanilla'
import {WelcomeBack} from '../welcome-back'
import {CTA} from '../../types/cms'
import {ProductCategoryCarousel} from '../product-category-carousel'
interface FavouritesCarouselProps {
  showFavourites: boolean
  subtitle?: string
  cta?: CTA
}

interface RecommenderCarouselProps {
  showRecommender: boolean
  einstein?: {
    recommenderId: string
  }
  category?: {
    name: string
    id: string
  }
}

interface ProductCategoryCarouselProps {
  showProductCategoryCarousel: boolean
  category?: {
    name: string
    id: string
  }
}

interface ProductCarouselProps {
  favouritesCarousel?: FavouritesCarouselProps
  recommenderCarousel?: RecommenderCarouselProps
  productCategoryCarousel?: ProductCategoryCarouselProps
  title?: string
}

export const ProductCarousel: SlotComponent<ProductCarouselProps> = ({content}) => {
  if (!content) {
    return null
  }
  const {favouritesCarousel, recommenderCarousel, productCategoryCarousel, title} = content

  return (
    <Stack>
      {favouritesCarousel?.showFavourites && (
          <WelcomeBack 
          cta={favouritesCarousel?.cta} 
          title={title} 
          subtitle={favouritesCarousel?.subtitle} />
      )}
      {recommenderCarousel?.einstein && (
        <LazyLoad once>
          <Recommender
            name={title}
            recommender={recommenderCarousel?.einstein?.recommenderId}
            categoryId={recommenderCarousel?.category?.id}
          />
        </LazyLoad>
      )}
      {productCategoryCarousel?.showProductCategoryCarousel && (
        <ProductCategoryCarousel 
          title={title}
          categoryId={productCategoryCarousel?.category?.id}
        />
      )}
    </Stack>
  )
}
